<template>
  <div class="bg-primary-700 py-12">
    <view-header title="Benefits Accounts Platform Upgrade" image="bg.jpg">
    </view-header>
  </div>
</template>

<script>
import ViewHeader from "../../../../components/layout/ViewHeaderMicroSite.vue";

export default {
  components: {
    ViewHeader
  }
};
</script>
