<template>
  <base-view id="current-video">
    <div
      class="flex flex-col lg:flex-row lg:justify-center w-full lg:px-24 my-0 max-w-7xl"
    >
      <div class="w-full lg:w-1/3">
        <quick-tip-sub-nav
          title="RMR Quick Tips"
          :links="links"
          @change-video="receiveVideo"
        />
      </div>
      <div
        class="flex flex-row mb-6 overflow-auto lg:flex-col w-full lg:w-2/3 lg:pl-12"
      >
        <div class="pt-0 lg:pt-16 pb-16 w-full h-full">
          <div class="w-full h-full flex flex-col items-center justify-center">
            <h1 class="mb-12 text-4xl ml-4 text-center font-semibold">
              {{ links[current - 1].title }}
            </h1>
            <div v-for="item in links" :key="item.id" class="w-full">
              <div v-if="item.id == current" class="w-full px-4">
                <vue-plyr>
                  <div class="plyr__video-embed">
                    <iframe
                      :src="
                        `https://www.youtube.com/embed/${
                          links[current - 1].video
                        }?iv_load_policy=3&modestbranding=1&playsinline=1&showinfo=0&rel=0&enablejsapi=1&controls=0`
                      "
                      allowfullscreen
                      allowtransparency
                      allow="autoplay"
                    ></iframe>
                  </div>
                </vue-plyr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import QuickTipSubNav from "@/components/layout/QuickTipSubNav.vue";

export default {
  components: {
    BaseView,
    QuickTipSubNav
  },
  data() {
    return {
      current: "1",
      links: [
        {
          title: "RMR Quick Tip Check Balance",
          video: "oNiWTOrlBww",
          id: "1"
        },
        {
          title: "RMR Quick Tip Submit A Claim",
          video: "XOQtc1rzbyI",
          id: "2"
        },
        {
          title: "RMR Quick Tip How To Register",
          video: "44AdnOh0kRs",
          id: "3"
        },
        {
          title: "RMR Quick Tip Use Your card",
          video: "W3MoBg5nlTM",
          id: "4"
        },
        {
          title: "RMR Quick Tip Uploading Documentation",
          video: "EHaU1cvbDgc",
          id: "5"
        },
        {
          title: "RMR Quick Tip Change Payment Method",
          video: "YSAa54ORB5E",
          id: "6"
        }
      ]
    };
  },
  methods: {
    receiveVideo(video) {
      this.current = video;
    }
  }
};
</script>
