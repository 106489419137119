<template>
  <div
    class="m-0 text-center text-white bg-center bg-cover 	"
    :style="{
      'background-image': 'url(' + require('@/assets/' + this.image) + ')'
    }"
  >
    <div class="w-auto h-full pt-24 pb-24 mx-4 my-0 lg:max-w-5xl lg:mx-auto">
      <h1 class="mt-0 mb-32 text-6xl font-bold text-white">{{ title }}</h1>
      <p class="max-w-2xl mx-auto my-0"><slot /></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    image: String
  }
};
</script>
