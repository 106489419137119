<template>
  <div class="overflow-hidden bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-24">
      <h1 class="text-2xl font-bold my-4">
        What to expect during the transition
      </h1>
      <h4 class="text-lg font-medium my-4">
        Your upgrade is <strong>planned for this September</strong> and we are
        dedicated to ensuring a smooth transition for our employer clients and
        your employees. We will update this microsite regularly and try to
        provide as much information to you as possible. Here are some answers to
        your immediate questions.
      </h4>
      <nav aria-label="Progress" class="w-full flex justify-center mt-8">
        <ol role="list" class="overflow-hidden">
          <li
            v-for="(step, stepIdx) in steps"
            :key="step.id"
            :class="[stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative']"
          >
            <template v-if="step.status === 'complete'">
              <div
                v-if="stepIdx !== steps.length - 1"
                class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-primary-800"
                aria-hidden="true"
              />
              <div class="group relative flex items-start">
                <span class="flex h-9 items-center">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary-800"
                  >
                    <img class="w-12" src="@/assets/images/check.svg" />
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 flex-col">
                  <span class="text-lg font-bold">{{ step.name }}</span>
                  <span
                    v-html="step.description"
                    class="text-sm text-neutral-800"
                  ></span>
                </span>
              </div>
            </template>
            <template v-else-if="step.status === 'current'">
              <div
                v-if="stepIdx !== steps.length - 1"
                class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-primary-800"
                aria-hidden="true"
              />
              <div class="group relative flex items-start" aria-current="step">
                <span class="flex h-9 items-center" aria-hidden="true">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary-800 bg-white"
                  >
                    <span class="h-2.5 w-2.5 rounded-full bg-primary-800" />
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 flex-col">
                  <span class="text-lg font-bold text-primary-800">{{
                    step.name
                  }}</span>
                  <span
                    v-html="step.description"
                    class="text-sm text-neutral-800"
                  ></span>
                </span>
              </div>
            </template>
            <template v-else>
              <div
                v-if="stepIdx !== steps.length - 1"
                class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-primary-800"
                aria-hidden="true"
              />
              <div :href="step.href" class="group relative flex items-start">
                <span class="flex h-9 items-center" aria-hidden="true">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary-800 bg-white "
                  >
                    <span class="h-2.5 w-2.5 rounded-full bg-transparent" />
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 flex-col">
                  <span class="text-lg font-bold text-primary-800">{{
                    step.name
                  }}</span>
                  <span
                    v-html="step.description"
                    class="text-sm text-neutral-800"
                  ></span>
                </span>
              </div>
            </template>
          </li>
        </ol>
      </nav>
      <p class="text-base mt-12 font-bold mx-4 md:mx-16">
        If HSA participants choose to consolidate their HSA by transferring
        their existing funds to their new account, the timeline for the transfer
        will be client specific and will be communicated through email.
      </p>
    </div>
  </div>
</template>

<script>
// import { CheckIcon } from "@heroicons/vue/20/solid";
export default {
  data() {
    return {
      steps: [
        {
          name: "7/8/2024",
          description: "<span>Announcement to Employers</span>",
          href: "#",
          status: this.checkDate("07/08/2024"),
          id: "0001"
        },
        {
          name: "7/18/2024",
          description: "<span>Announcement to Employees from RMR</span>",
          href: "#",
          status: this.checkDate("07/18/2024"),
          id: "0002"
        },
        {
          name: "8/2/2024",
          description:
            "<span>HSA Transfer communication emailed to HSA participants</span>",
          href: "#",
          status: this.checkDate("08/02/2024"),
          id: "0022"
        },
        {
          name: "8/15/2024",
          description: "<span>New Portal Training Material released</span>",
          href: "#",
          status: this.checkDate("08/15/2024"),
          id: "0012"
        },
        {
          name: "8/21/2024 - 9/1/2024",
          description: "<span>New Participant Cards begin arriving</span>",
          href: "#",
          status: this.checkDate("08/21/2024"),
          id: "0003"
        },
        {
          name: "9/1/2024 - 9/15/2024",
          description:
            "<span>Beginning of <a style='text-decoration:underline;' href='#9'>Quiet Period</a> for all NON-HSA benefits</span>",
          href: "#",
          status: this.checkDate("09/01/2024"),
          id: "0004"
        },
        {
          name: "9/15/2024",
          description:
            "<span>Quiet Period is expected to end for all NON-HSA benefits</span>",
          href: "#",
          status: this.checkDate("09/15/2024"),
          id: "0005"
        }
      ]
    };
  },
  methods: {
    checkDate(dateProp) {
      var newDate = new Date(dateProp.slice(0, 10));
      var today = new Date();
      var upcoming = new Date(newDate);
      upcoming.setDate(upcoming.getDate() - 6);
      if (today >= newDate) {
        return "complete";
      } else if (today >= upcoming) {
        return "current";
      } else if (newDate < today) {
        return "upcoming";
      }
    }
  }
};
</script>
