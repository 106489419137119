<template>
  <div class="w-auto h-full my-0 lg:max-w-6xl">
    <div
      class="flex flex-col justify-start w-auto h-full pt-16 pb-16 my-0 lg:flex-row"
    >
      <div class="flex flex-row">
        <div class="w-full text-left lg:w-80 nav first:mt-0">
          <h3 class="mb-2 text-xl font-semibold">{{ title }}</h3>
          <div class="flex flex-row mb-6 overflow-auto lg:flex-col">
            <div
              v-for="route in links"
              :key="route.id"
              @click="changeVideo(route.id)"
              class="block p-2 text-base transition-colors border-b rounded-t lg:border-b-0 whitespace-nowrap lg:rounded-b hover:bg-neutral-light active:bg-neutral cursor-pointer"
            >
              {{ route.title }}
            </div>
          </div>
        </div>
        <div class="hidden mx-4 border-r lg:block" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    links: Array
  },
  methods: {
    changeVideo(current) {
      this.$emit("change-video", current);
    }
  }
};
</script>

<style scoped>
a.router-link-active {
  color: theme("colors.primary.light");
  background-color: theme("colors.neutral.light");
  border-bottom: 0.25rem solid theme("colors.primary.light");
  border-right: none;
}

@media only screen and (min-width: 1024px) {
  a.router-link-active {
    border-right: 0.25rem solid theme("colors.primary.light");
    border-bottom: none;
  }
}
</style>
