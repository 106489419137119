<template>
  <footer
    id="footer"
    class="relative text-base leading-snug text-white bg-primary-700"
  >
    <div
      class="relative flex flex-col w-auto h-full px-0 pt-12 pb-8 mx-4 my-0 xl:max-w-6xl xl:mx-auto"
    >
      <div class="w-full mb-8">
        <router-link to="/">
          <img src="~@/assets/RMRGreen_Logo_White.png" class="mx-auto h-16" />
        </router-link>
      </div>
      <div
        class="flex flex-col flex-wrap justify-around align-top md:flex-row md:justify-center"
      >
        <div
          class="relative flex flex-col items-center order-first mb-16 md:order-none md:mb-8 md:w-md "
        >
          <h1 class="text-xl font-bold mt-4 mb-12 ">
            Got questions? Don't hesitate to contact your dedicated account
            manager via phone or email for assistance. We're here to address any
            concerns you may have throughout the transition process.
            Additionally, stay tuned as we'll be updating this site with helpful
            trainings and additional FAQs as we progress through the timeline.
            Your success is our priority!
          </h1>
        </div>
      </div>
      <p class="w-full text-center copyright">
        Copyright © 2021 Rocky Mountain Reserve
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  components: {},
  mounted() {
    this.$zendesk.load("a95486a9-0bcf-4bda-99ac-f81566ab1958");
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    setClipboard(value) {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    }
  }
};
</script>

<style scoped>
#linkedin {
  background: url("~@/assets/socialMedia/LinkedIn-White.png");
  background-size: contain;
}

#linkedin:hover {
  background: url("~@/assets/socialMedia/LinkedIn-Color.png");
  background-size: contain;
}

#twitter {
  background: url("~@/assets/socialMedia/Twitter-White.png");
  background-size: contain;
}

#twitter:hover {
  background: url("~@/assets/socialMedia/Twitter-Color.png");
  background-size: contain;
}

#facebook {
  background: url("~@/assets/socialMedia/Facebook-White.png");
  background-size: contain;
}

#facebook:hover {
  background: url("~@/assets/socialMedia/Facebook-Color.png");
  background-size: contain;
}
</style>
